import React from "react";
import JSZip from "jszip"
import {
  Popconfirm,
  Popover,
  Upload,
  Avatar,
  Layout,
  Icon,
  Row,
  Col,
  Divider,
  Modal,
  Carousel,
  Tag,
  Tabs,
  Button as AntButton,
  Input,
  Spin,
  Empty,
  List,
  DatePicker,
  Comment,
  Switch,
  Dropdown,
  Menu,
  message,
} from "antd";
import { Amplify } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import {
  getPatientChat,
  updatePatientChat,
  getAligners,
  updatePatient,
  getPatients,
  deletePatient,
  uploadPatientFile,
} from "./Patients";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { ClickAwayListener } from "@material-ui/core";
import { Radio } from "antd";
import Header from "./Header";
import { Avatar as ArchFormAvatar } from "./Avatar";
import { Tag as ArchFormTag } from "./Tag";
import { tag_color, stringToColour } from "./PatientTable";
import { getUser } from "./User";
import stl_upper_bw from "./composite2/STL_UpperVector01.png";
import stl_lower_bw from "./composite2/STL_LowerVector01.png";
import stl_upper_fill from "./media/patient/stl_upper_fill.svg";
import stl_upper_outline from "./media/patient/stl_upper_outline.svg";
import stl_lower_fill from "./media/patient/stl_lower_fill.svg";
import stl_lower_outline from "./media/patient/stl_lower_outline.svg";

import full_face_smiling from "./media/patient/full_face_smiling.svg";
import full_face_not_smiling from "./media/patient/full_face_not_smiling.svg";
import profile_not_smiling from "./media/patient/profile_not_smiling.svg";
import upper_occlusal from "./media/patient/upper_occlusal.svg";
import lower_occlusal from "./media/patient/lower_occlusal.svg";
import right_lateral from "./media/patient/right_lateral.svg";
import front_view from "./media/patient/front_view.svg";
import left_lateral from "./media/patient/left_lateral.svg";

import Sidebar, { status_color_map } from "./Sidebar";
import {
  ArrowUpRight,
  Paperclip,
  Image,
  Edit,
  Plus,
  File as FileReact,
  Trash,
  ChevronDown,
  Search,
  Copy,
  Clipboard,
  PlusCircle,
} from "react-feather";
import { Button, ButtonDropdownItem } from "./Button";
import "./PatientView.css";
import { hashCode, sha } from "./Patients";
import { AddressViewSimple } from "./AddressView";
import { FileUpload } from "./CreatePatient";
import AWS from "aws-sdk";
import RetainerOrder from "./RetainerOrder";
import reactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import { get_value } from "./db";
import PrescriptionView from "./PrescriptionView";
import { PhotoFilter } from "@material-ui/icons";

import { SCANS_ACCRPTED } from "./utils";

AWS.config.region = "us-east-2";
const s3 = new AWS.S3();

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Content } = Layout;
const { TextArea } = Input;

export function convertUNS(num, system) {
  if (system === "Palmer") {
    if (num < 9) return 9 - num;
    if (num < 17) return num - 8;
    if (num < 25) return 25 - num;
    return num - 24;
  } else if (system === "ISO") {
    if (num < 9) return 19 - num;
    if (num < 17) return num + 12;
    if (num < 25) return 55 - num;
    return num + 16;
  } else if (system === "UNS") {
    return num;
  }
  console.error("Cannot convert UNS to " + system);
}

export function encodePatientName(patient) {
  return window.btoa(
    (patient.firstname ? patient.firstname : "") +
      (patient.lastname ? " " + patient.lastname : "")
  );
}

const EditPatientFieldModal = (patient, key, onOk) => (
  <Modal
    title="Edit Field"
    visible={true}
    closable={true}
    onOk={this.handleFieldChange}
    onCancel={() =>
      this.setState({ editPatientFieldModalVisible: false, chatMsgText: "" })
    }
  >
    <TextArea
      autosize={{ minRows: 8 }}
      value={this.state.chatMsgText}
      onChange={(evt) => this.setState({ chatMsgText: evt.target.value })}
    />
  </Modal>
);

const status_description = {
  "On Hold": "Case on hold",
  Unprocessed: "Case not assigned a status",
  Planning: "Case being setup",
  "Needs Review": "Case needs review",
  Accepted: "Aligners ready for fabrication",
  "In Production": "Aligners being fabricated",
  "In Treatment": "Patient being treated",
  Archived: "Treatment complete",
};

export const TeethTable = ({ table, system }) => (
  <Row className="teeth-checkbox teeth-table">
    <Col span={24} style={{ display: "flex" }}>
      <div>
        <div>
          {[...Array(9).keys()].slice(1).map((val) => {
            return table.includes(val.toString()) ? (
              <div
                key={val}
                className="teeth-checkbox-item teeth-checkbox-selected"
              >
                {convertUNS(val, system)}
              </div>
            ) : (
              <div key={val} className="teeth-checkbox-item">
                {convertUNS(val, system)}
              </div>
            );
            // <Select size="small"> {val}</Select>:
            // <Select size="small"> {val}</Select>
          })}
        </div>
        <Divider />
        <div>
          {[...Array(33).keys()]
            .slice(25)
            .reverse()
            .map((val) => {
              return table.includes(val.toString()) ? (
                <div
                  key={val}
                  className="teeth-checkbox-item teeth-checkbox-selected"
                >
                  {convertUNS(val, system)}
                </div>
              ) : (
                <div key={val} className="teeth-checkbox-item">
                  {convertUNS(val, system)}
                </div>
              );
            })}
        </div>
      </div>
      <div>
        <Divider style={{ height: "100%" }} type="vertical" />
      </div>
      <div>
        <div>
          {[...Array(17).keys()].slice(9).map((val) => {
            return table.includes(val.toString()) ? (
              <div
                key={val}
                className="teeth-checkbox-item teeth-checkbox-selected"
              >
                {convertUNS(val, system)}
              </div>
            ) : (
              <div key={val} className="teeth-checkbox-item">
                {convertUNS(val, system)}
              </div>
            );
          })}
        </div>
        <Divider />
        <div>
          {[...Array(25).keys()]
            .slice(17)
            .reverse()
            .map((val) => {
              return table.includes(val.toString()) ? (
                <div
                  key={val}
                  className="teeth-checkbox-item teeth-checkbox-selected"
                >
                  {convertUNS(val, system)}
                </div>
              ) : (
                <div key={val} className="teeth-checkbox-item">
                  {convertUNS(val, system)}
                </div>
              );
            })}
        </div>
      </div>
    </Col>
  </Row>
);

const StatusSelectorDropdown = ({ status, setStatus, setClickStatus }) => {
  let dropdown_list_ref = null;

  const hide_list = () => {
    if (dropdown_list_ref) dropdown_list_ref.style.display = "none";
  };
  const show_list = () => {
    if (dropdown_list_ref) dropdown_list_ref.style.display = "flex";
  };

  return (
    <div
      onMouseLeave={hide_list}
      className="status-selector-dropdown"
      style={{ marginRight: "8px" }}
    >
      <p onClick={show_list} style={{ color: status_color_map[status] }}>
        Change status
      </p>
      <ChevronDown
        size={24}
        color={status_color_map[status]}
        onClick={show_list}
      />
      <div
        className="status-selector-dropdown-list"
        ref={(ref) => (dropdown_list_ref = ref)}
        onMouseLeave={hide_list}
      >
        {[
          "On Hold",
          "Unprocessed",
          "Planning",
          "Needs Review",
          "Accepted",
          "In Production",
          "In Treatment",
          "Archived",
        ].map((choice) => (
          <p
            onClick={() => {
              setTimeout(() => {
                hide_list();
                setStatus(choice);
              }, 50);
            }}
            key={choice}
          >
            {choice}
          </p>
        ))}
      </div>
    </div>
  );
};

class PatientView extends React.Component {
  constructor(props) {
    super(props);

    const patient = {
      ...props.patients.find((patient) => {
        if (
          this.props.match.params.num.match(/-/g) &&
          this.props.match.params.num.match(/-/g).length === 4
        )
          return patient.patientid === this.props.match.params.num;
        else return hashCode(patient) === this.props.match.params.num;
      }),
    };
    if (Object.keys(patient).length) {
      patient.clinical_conditions = patient.clinical_conditions
        ? patient.clinical_conditions
        : [];
    }

    this.state = {
      modalImg: null,
      imgModalVisible: false,
      chatModalVisible: false,
      chatMsgTitle: "Edit Note",
      patient: patient,
      chatMsgText: "",
      chatMsgFiles: [],
      chatLoaded: false,
      chatMessages: [],
      files: [],
      filesLoading: false,
      filesUploading: false,
      archformFilesLoading: false,
      fileDates: {},
      editing_preferences: false,
      user: null,
      loadedChatMsgFiles: {},
      chatImgModalVisible: false,
      newRefinementModalVisible: false,
      newUpperRefinementFile: false,
      newLowerRefinementFile: false,
      replaceScansModalVisible: false,
      replacementLowerScanFile: [],
      replacementUpperScanFile: [],
      publicWebViewerLinkModalVisible: false,
      publicWebViewerLinkClicked: false,
      retainerOrderModalVisible: false,
      patientSHA: "",
      refinementComposite: {},
      uploadProgress: 0,
    };

    getUser().then((user) => this.setState({ user: user }));
    this.carousel = React.createRef();
    this.fileUploadRef = React.createRef();

    this.handlePatientsStatusChange =
      this.handlePatientsStatusChange.bind(this);

    this.handleNewChatMsg = this.handleNewChatMsg.bind(this);
    this.handleChatMsgFiles = this.handleChatMsgFiles.bind(this);
    this.handlePatientFieldChange = this.handlePatientFieldChange.bind(this);
    this.handlePatientStatusChange = this.handlePatientStatusChange.bind(this);
    this.handlePatientNextStatusChange =
      this.handlePatientNextStatusChange.bind(this);
    this.handlePatientSetupNeedsChanges =
      this.handlePatientSetupNeedsChanges.bind(this);
    this.uploadArchformFile = this.uploadArchformFile.bind(this);
    this.handleOpenInArchform = this.handleOpenInArchform.bind(this);
    this.setXrayModalIdx = this.setXrayModalIdx.bind(this);
    this.handleDeletePatient = this.handleDeletePatient.bind(this);
    this.handleNewRefinement = this.handleNewRefinement.bind(this);
    this.handleUpdateScans = this.handleUpdateScans.bind(this);
    this.handleOpenWebViewer = this.handleOpenWebViewer.bind(this);
    this.getPublicWebViewerUrl = this.getPublicWebViewerUrl.bind(this);
    this.handleNewRetainerOrder = this.handleNewRetainerOrder.bind(this);
    this.handleDeleteRefinementFile =
      this.handleDeleteRefinementFile.bind(this);
    this.getCurrentRefinementVersion =
      this.getCurrentRefinementVersion.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (true) {
      const patient = {
        ...this.props.patients.find((patient) => {
          if (
            this.props.match.params.num.match(/-/g) &&
            this.props.match.params.num.match(/-/g).length === 4
          )
            return patient.patientid === this.props.match.params.num;
          else return hashCode(patient) === this.props.match.params.num;
        }),
      };
      if (patient.lastModified !== prevState.patient.lastModified) {
        this.setState({ patient });
      }
    }
    if (!Object.keys(prevState.patient).length) {
      const patient = {
        ...this.props.patients.find((patient) => {
          if (
            this.props.match.params.num.match(/-/g) &&
            this.props.match.params.num.match(/-/g).length === 4
          )
            return patient.patientid === this.props.match.params.num;
          else return hashCode(patient) === this.props.match.params.num;
        }),
      };

      if (Object.keys(patient).length) {
        patient.clinical_conditions = patient.clinical_conditions
          ? patient.clinical_conditions
          : [];
        this.setState({ patient }, async () => {
          await this.loadPatientData();
        });
      }
    }
  }

  async loadPatientData() {
    const patient = this.state.patient;
    const patientSHA = await sha(patient);
    this.setState({ patientSHA });
    await this.loadFiles();

    let chatMessages = await getPatientChat(this.state.patient.patientid);
    const unfiltered_status_timeline = this.state.patient.status_timeline;
    let status_timeline = [];
    let previous_status = "";
    try {
      for (const status of unfiltered_status_timeline) {
        let _status;
        if (status.length === 2) _status = status[0];
        else _status = status[0][0];

        if (_status !== previous_status) {
          status_timeline.push(status);
        }
        previous_status = _status;
      }
    } catch (err) {
      status_timeline = unfiltered_status_timeline;
    }
    if (this.state.files.archform && this.state.files.archform.length) {
      for (const model of this.state.files.archform) {
        chatMessages.push({
          username: "user",
          date: this.state.fileDates[model],
          type: "file",
          message: model,
        });
      }
    }
    if (this.state.files.refinements && this.state.files.refinements.length) {
      const refinement_vers = {};
      for (const model of this.state.files.refinements) {
        const model_version = model
          .split("?")[0]
          .split("/")
          .slice(-1)[0]
          .split("_")
          .slice(-1)[0]
          .split(".stl")[0];
        if (!refinement_vers[model_version]) {
          chatMessages.push({
            username: "user",
            date: this.state.fileDates[model],
            type: "refinement",
            message: model_version,
          });
          refinement_vers[model_version] = 1;
        }
      }
    }
    if (status_timeline && status_timeline.length) {
      chatMessages = chatMessages.concat(status_timeline);
      chatMessages.sort((a, b) => {
        if (a.constructor === Array) {
          if (a.length === 1) a = a[0][1];
          else a = a[1];
        } else a = a.date;

        if (b.constructor === Array) {
          if (b.length === 1) b = b[0][1];
          else b = b[1];
        } else b = b.date;
        return moment(a) - moment(b);
      });
    }
    if (!Array.isArray(chatMessages)) chatMessages = [];
    chatMessages.reverse();

    this.setState({ chatMessages, chatLoaded: true });

    await this.loadNoteImages();

    // TODO: Fix the issue for patient of needs review files upload

    // let patient_changed = false;
    // if (!patient.status) {
    //   patient.status = "Unprocessed";
    //   patient_changed = true;
    // }
    // if (!patient.manufacturing) {
    //   patient.manufacturing = "In Office";
    //   patient_changed = true;
    // }
    // if (!patient.alignment) {
    //   patient.alignment = "In Office";
    //   patient_changed = true;
    // }
    // if (patient_changed) {
    //   this.setState({ patient: patient });
    //   await updatePatient(patient);
    // }
  }

  async componentDidMount() {
    if (Object.keys(this.state.patient).length) {
      await this.loadPatientData();
    }
  }

  setImgModal = (idx) => (evt) => {
    this.setState({ imgModalVisible: true, carouselIdx: idx });
    if (this.carousel.current) this.carousel.current.goTo(idx);
  };

  async loadNoteImages() {
    this.setState({ filesLoading: true });
    let files = {};
    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patient-data-v1",
    };
    const filenames = await Amplify.Storage.list(
      `${this.state.patient.patientid}/notes`,
      options
    );

    for (const filename of filenames.results) {
      const f = await Amplify.Storage.get(filename.key, options);
      const file_date = filename.key.split("notes/")[1].split("/")[0];
      if (!files[file_date]) files[file_date] = [];
      files[file_date].push(f);
    }
    this.setState({ loadedChatMsgFiles: files, filesLoading: false });
  }

  async loadFiles() {
    this.setState({ archformFilesLoading: true, filesLoading: true });
    let archform_files = [];
    for (const folder of [
      "composite",
      "xray",
      "models",
      "retainers",
      "refinements",
      "archform-patients-v1",
    ]) {
      const options = {
        level: "protected",
        identityId: this.state.patient.userid,
        bucket: "archform-patient-data-v1",
      };
      if (folder === "archform-patients-v1") {
        options.customPrefix = { protected: "" };
        options.bucket = folder;
      }
      const filenames = await Amplify.Storage.list(
        folder === "archform-patients-v1"
          ? `${this.state.patient.patientid}/`
          : `${this.state.patient.patientid}/${folder}`,
        options
      );
      let files = [];
      if (folder === "composite") {
        files = {};
      }
      filenames.results.sort((a, b) => {
        const aa = a.lastModified;
        const bb = b.lastModified;
        if (!aa || !bb) return 0;
        if (aa < bb) {
          return -1;
        } else if (aa > bb) {
          return 1;
        } else {
          return 0;
        }
      });
      const file_dates = { ...this.state.fileDates };
      for (const filename of filenames.results) {
        const f = await Amplify.Storage.get(filename.key, options);
        if (folder === "composite") {
          files[filename.key.split("/").slice(-1)[0].split(".jpg")[0]] = f;
        } else {
          files.push(f);
        }
        file_dates[f] = filename.lastModified;
      }
      if (files.length || folder === "composite") {
        if (folder === "archform-patients-v1") files.reverse();
        this.setState({
          files: {
            ...this.state.files,
            [folder === "archform-patients-v1" ? "archform" : folder]: files,
          },
          fileDates: { ...file_dates },
        });
        if (folder === "archform-patients-v1") archform_files = files;
      }
    }
    const creds = await Amplify.Auth.currentCredentials();
    s3.config.credentials = creds;
    const params = {
      Bucket: "archform-patient-data-v1",
      Prefix: `protected/${this.state.patient.userid}/${this.state.patient.patientid}/web_viewer/`,
      Delimiter: "/",
    };
    const res = await s3.listObjectsV2(params).promise();
    const web_viewer = res.CommonPrefixes.map(
      (prefix) => prefix.Prefix.split("/")[4]
    );
    web_viewer.sort((a, b) => {
      if (a.split("_").length !== 3 || b.split("_").length !== 3) return 0;
      a = parseInt(a.split("_")[0]);
      b = parseInt(b.split("_")[0]);
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    this.setState({
      files: {
        ...this.state.files,
        web_viewer: web_viewer,
      },
      filesLoading: false,
      archformFilesLoading: false,
    });
  }

  async handleDeleteArchformFile(file, link, folder) {
    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patients-v1",
      customPrefix: { protected: "" },
    };

    const res = await Amplify.Storage.remove(
      decodeURI(`${this.state.patient.patientid}/${file}`),
      options
    );
    const files = this.state.files;
    files[folder] = files[folder].filter((ii) => ii !== link);
    this.setState({ files: files });
  }

  // async uploadArchformFile(file) {
  //   this.setState({ filesUploading: true });
  //   const res = await Amplify.Storage.put(
  //     `${this.state.patient.patientid}/${file.file.name}`,
  //     file.file,
  //     {
  //       level: "protected",
  //       identityId: this.state.patient.userid,
  //       bucket: "archform-patients-v1",
  //       customPrefix: { protected: "" },
  //       contentType: file.file.type,
  //     }
  //   );
  //   this.setState({
  //     filesUploading: false,
  //     files: {
  //       ...this.state.files,
  //       archform: this.state.files.archform
  //         ? this.state.files.archform.concat(file.file.name)
  //         : [file.file.name],
  //     },
  //   });
  // }

  async uploadArchformFile2(file) {
    const fileName = file.file.name.toLowerCase();
    const isZIP = fileName.endsWith(".zip");

    if (!isZIP) {
      message.error(`${file.file.name} is not a zip`);
      return false;
    }

    this.setState({ filesUploading: true });
    await uploadPatientFile(
      file.file.name,
      file.file,
      "setup",
      this.state.patient,
      (uploadProgress) => {
        this.setState({ uploadProgress });
      }
    );
    // const res = await Amplify.Storage.put(
    //   `${this.state.patient.patientid}/${file.file.name}`,
    //   file.file,
    //   {
    //     level: "protected",
    //     identityId: this.state.patient.userid,
    //     bucket: "archform-patients-v1",
    //     customPrefix: { protected: "" },
    //     contentType: file.file.type,
    //   }
    // );
    this.setState({
      filesUploading: false,
      files: {
        ...this.state.files,
        archform: this.state.files.archform
          ? this.state.files.archform.concat(file.file.name)
          : [file.file.name],
      },
    });
  }

  async uploadArchformFile(file) {
    const fileName = file.file.name
    const fileArr = fileName.split(".")
    const suffix = fileArr[fileArr.length - 1].toLowerCase()
    const isZIP = fileName.endsWith(".zip")
    const isArchForm = fileName.endsWith(".archform")

    if (!isZIP && !isArchForm) {
      message.error(
        `The file format of ${suffix} is not supported, please select .archform file or compressed .archform file (.zip).`
      )
      return false
    }

    let targetFile = file.file

    if (isArchForm) {
      const zip = new JSZip()
      const fileName = targetFile.name + ".zip"
      zip.file(targetFile.name, targetFile, { base64: true })
      const zipedFile = await zip.generateAsync({ type: "blob" })
      targetFile = new File([zipedFile], fileName, { type: "text/plain" })
    }
    this.setState({ filesUploading: true })

    try {
      await uploadPatientFile(
        targetFile.name,
        targetFile,
        "setup",
        this.state.patient,
        (uploadProgress) => {
          this.setState({ uploadProgress })
        }
      )

      this.setState({
        filesUploading: false,
        files: {
          ...this.state.files,
          archform: this.state.files.archform
            ? this.state.files.archform.concat(targetFile.name)
            : [targetFile.name],
        },
      })
    } catch (err) {
      message.error(`Setup Version upload fail`)
      this.setState({
        filesUploading: false,
      })
    }
  }

  async handleDeleteRefinementFile(file, link) {
    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
    };

    const res = await Amplify.Storage.remove(
      decodeURI(`${this.state.patient.patientid}/refinements/${file}`),
      options
    );
    const files = this.state.files;
    files.refinements = files.refinements.filter((ii) => ii !== link);
    this.setState({ files: files });
  }

  getCurrentRefinementVersion() {
    const refinements = this.state.files.refinements || [];

    let current_refinement_version = refinements
      .sort((a, b) => {
        if (
          a.split("_refinement_").length !== 2 ||
          b.split("_refinement_").length !== 2
        )
          return 0;
        a = parseInt(a.split("_refinement_")[1].split(".stl")[0]);
        b = parseInt(b.split("_refinement_")[1].split(".stl")[0]);
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      })
      .slice(-1);

    current_refinement_version = current_refinement_version[0]
      ? current_refinement_version[0].split("_refinement_")[1].split(".stl")[0]
      : 0;
    return parseInt(current_refinement_version);
  }

  async handleNewRefinement(evt) {
    const upper = this.state.newUpperRefinementFile;
    const lower = this.state.newLowerRefinementFile;
    if (!upper || !lower) return;

    const refinement_files = Object.values(this.state.refinementComposite);
    if (refinement_files && this.state.chatMsgText === "") {
      this.setState({ chatMsgText: " " });
    }
    this.handleChatMsgFiles(refinement_files);
    const refinements = this.state.files.refinements || [];
    this.setState({ filesUploading: true, newRefinementModalVisible: false });

    const refinement_version = this.getCurrentRefinementVersion() + 1;

    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patient-data-v1",
    };
    await Amplify.Storage.put(
      `${this.state.patient.patientid}/refinements/stl_upper_refinement_${refinement_version}.stl`,
      upper,
      options
    );
    await Amplify.Storage.put(
      `${this.state.patient.patientid}/refinements/stl_lower_refinement_${refinement_version}.stl`,
      lower,
      options
    );

    this.handleNewChatMsg();
    this.handlePatientStatusChange("Unprocessed");
    this.setState({
      filesUploading: false,
      files: {
        ...this.state.files,
        refinements: [
          ...refinements,
          URL.createObjectURL(upper),
          URL.createObjectURL(lower),
        ],
      },
    });
  }

  async handleUpdateScans(evt) {
    const upper = this.state.replacementUpperScanFile;
    const lower = this.state.replacementLowerScanFile;
    if (!upper || !lower) return;
    this.setState({ filesUploading: true, replaceScansModalVisible: false });

    const options = {
      level: "protected",
      identityId: this.state.patient.userid,
      bucket: "archform-patient-data-v1",
    };

    await Amplify.Storage.put(
      `${this.state.patient.patientid}/models/stl_upper.stl`,
      upper.originFileObj,
      options
    );
    await Amplify.Storage.put(
      `${this.state.patient.patientid}/models/stl_lower.stl`,
      lower.originFileObj,
      options
    );

    const filenames = await Amplify.Storage.list(
      `${this.state.patient.patientid}/models`,
      options
    );
    const models = [];
    for (const filename of filenames.results) {
      const f = await Amplify.Storage.get(filename.key, options);
      models.push(f);
    }

    this.setState({
      filesUploading: false,
      files: {
        ...this.state.files,
        models,
      },
    });
  }
  async handleNewRetainerOrder({
    arches_to_fab,
    trim_style,
    retainer_manufacturing,
    stl_lower,
    stl_upper,
    comment,
  }) {
    this.setState({ filesUploading: true });
    const retainers = [];
    if (stl_lower) {
      await uploadPatientFile(
        "retainers/stl_lower.stl",
        stl_lower,
        "retainers",
        this.state.patient,
        (uploadProgress) => {
          this.setState({ uploadProgress });
        }
      );
      retainers.push(stl_lower.name);
    }
    if (stl_upper) {
      await uploadPatientFile(
        "retainers/stl_upper.stl",
        stl_upper,
        "retainers",
        this.state.patient,
        (uploadProgress) => {
          this.setState({ uploadProgress });
        }
      );
      retainers.push(stl_upper.name);
    }
    const patient = {
      ...this.state.patient,
      manufacturing: "Retainer",
      status: "Accepted",
      clinical_preferences: {
        ...this.state.patient.clinical_preferences,
        retainer_trim_style: trim_style,
        retainer_arches_to_fab: arches_to_fab,
        retainer_manufacturing: retainer_manufacturing,
      },
    };
    await updatePatient(patient);
    this.setState(
      {
        patient,
        chatMsgText: comment,
      },
      async () => {
        await this.handleNewChatMsg();
      }
    );

    // const res = await Amplify.Storage.put(
    //   `${this.state.patient.patientid}/${file.file.name}`,
    //   file.file,
    //   {
    //     level: "protected",
    //     identityId: this.state.patient.userid,
    //     bucket: "archform-patients-v1",
    //     customPrefix: { protected: "" },
    //     contentType: file.file.type,
    //   }
    // );

    this.setState({
      filesUploading: false,
      files: {
        ...this.state.files,
        retainers,
      },
    });

    await this.handlePatientsStatusChange();
  }

  async handleNewChatMsg() {
    if (this.state.chatMsgText === "") return;
    const date = new Date().toISOString();
    const message = {
      userid: (await Amplify.Auth.currentCredentials()).identityId,
      date: date,
      username: Amplify.Cache.getItem("user").name,
      patientid: this.state.patient.patientid,
      message: this.state.chatMsgText,
      patient_userid: this.state.patient.userid,
    };
    this.setState({ filesUploading: true, chatModalVisible: false });
    await updatePatientChat(message);
    const newLoadedChatMsgFiles = { [message.date]: [] };
    for (const file of this.state.chatMsgFiles) {
      const res = await Amplify.Storage.put(
        `${this.state.patient.patientid}/notes/${date}/${file.name}`,
        file,
        {
          level: "protected",
          identityId: this.state.patient.userid,
          bucket: "archform-patient-data-v1",
          contentType: file.type,
        }
      );
      newLoadedChatMsgFiles[message.date].push(URL.createObjectURL(file));
    }
    if (
      this.state.chatMsgTitle == "What changes need to be made to the setup?"
    ) {
      // const patient = { ...this.state.patient, status: "Planning" };
      // await updatePatient({ patient });
      // this.setState({
      //   patient,
      //   patient_status: "Planning",
      // });
      await this.handlePatientStatusChange("Planning");
    }
    this.setState({
      chatMessages: [message, ...this.state.chatMessages],
      chatMsgText: "",
      chatMsgTitle: "Edit Note",
      filesUploading: false,
      chatMsgFiles: [],
      loadedChatMsgFiles: {
        ...this.state.loadedChatMsgFiles,
        ...newLoadedChatMsgFiles,
      },
    });
    this.fileUploadRef.current.value = "";
  }

  // Do not use until updatePatient stops using Put!
  handlePatientFieldChange = (key) => async (evt) => {
    // const value = evt.target.value;
    // if (this.state.patient[key] === value) return;
    // this.setState(({ patient }) => {
    //     patient[key] = value;
    //     return patient
    // });
    // const d = await updatePatient({
    //     userid: this.state.patient.userid,
    //     patientid: this.state.patient.patientid,
    //     [key]: value,
    // })
    // // if (this.state.editing_preferences) return;
  };

  async handlePatientStatusChange(status) {
    if (
      this.state.patient.status === "Unprocessed" &&
      this.state.patient.alignment !== "In Office" &&
      this.state.user.attributes["custom:user_type"] !== "Technician"
    )
      return message.warn(
        "Only technicians can update the state of this patient"
      );
    this.setState({
      patient: { ...this.state.patient, status: status },
      patient_status: status,
    });
    await updatePatient({ ...this.state.patient, status: status });
    await this.handlePatientsStatusChange();
  }

  async handlePatientsStatusChange() {
    this.props.onChangeStatus();
    this.loadPatientData();
  }

  async handlePatientNextStatusChange(evt) {
    if (
      this.state.patient.status === "Unprocessed" &&
      this.state.patient.alignment !== "In Office" &&
      this.state.user.attributes["custom:user_type"] !== "Technician"
    )
      return message.warn(
        "Only technicians can update the state of this patient"
      );
    let new_status = "";
    const status = this.state.patient.status;
    if (status === "Archived") return;
    if (status === "On Hold") new_status = "Unprocessed";
    if (status === "Unprocessed") new_status = "Planning";
    if (status === "Planning") new_status = "Needs Review";
    if (status === "Needs Review") new_status = "In Production";
    if (status === "Needs Review") new_status = "Accepted";
    if (status === "Accepted") new_status = "In Production";
    if (status === "In Production") new_status = "In Treatment";
    if (status === "In Treatment") new_status = "Archived";
    this.setState({
      patient: { ...this.state.patient, status: new_status },
      patient_status: new_status,
    });
    await updatePatient({ ...this.state.patient, status: new_status });
  }

  async handlePatientSetupNeedsChanges(evt) {
    this.setState({
      chatModalVisible: true,
      chatMsgTitle: "What changes need to be made to the setup?",
      // chatMsgFiles: [],
      // chatMsgText: "",
    });
    this.fileUploadRef.current.value = "";
  }

  handleOpenInArchform(filename = "", refinement = -1) {
    if (!this.state.patient) return;

    const unity_data = {
      UUID: this.state.patient.patientid,
      IdentityId: this.state.patient.userid,
    };
    window.location =
      "archform:" +
      encodeURIComponent(
        JSON.stringify({
          ...unity_data,
          filename: filename,
          refinement: refinement,
        })
      );
  }

  handleOpenWebViewer(filename = "") {
    if (
      !this.state.files ||
      !this.state.files.web_viewer ||
      !this.state.files.web_viewer.length
    ) {
      return;
    }
    if (!filename) {
      const last_archform_file_name = this.state.files.archform[0]
        .split("?")[0]
        .split("/")
        .slice(-1)[0];
      if (this.state.files.web_viewer.includes(last_archform_file_name)) {
        filename = last_archform_file_name;
      } else {
        filename =
          this.state.files.web_viewer[this.state.files.web_viewer.length - 1];
      }
    }
    const patient = this.state.patient;
    window.open(
      `${window.location.origin}/threedview/${patient.userid}/${
        patient.patientid
      }/${filename}/${encodePatientName(patient)}`
    );
  }

  async handleDeletePatient() {
    await deletePatient(this.state.patient.patientid);
    this.props.history.push("/");
  }

  handleChatMsgFiles = (files) => {
    this.setState({
      chatMsgFiles: [...files],
    });
  };

  getPublicWebViewerUrl() {
    const patient = this.state.patient;
    const patientSHA = this.state.patientSHA;
    return `${
      window.location.origin
    }/threedview/${patientSHA}/${patient.firstname.replace(/\s/g, "")}${
      patient.lastname ? patient.lastname[0] : ""
    }/`;
  }

  // async handleGetPublicViewer() {
  //   const patient = this.state.patient;
  //   const public_url = `${window.location.origin}/threedview/${(await sha(patient))}/${patient.firstname}${patient.lastname ? patient.lastname[0] : ""}/`
  //   const modal = Modal.info({
  //     title: 'Direct link to the 3D web viewer',
  //     width: 800,
  //     className: 'public-viewer-modal',
  //     content: (
  //       <div className='public-viewer-modal-container'>
  //         <p>Minimal view for patients</p>
  //         <div className='public-viewer-modal-row'>

  //           <a target='_blank' href={public_url + 'minimal'}>{public_url}minimal</a>
  //           <Button
  //             style={{ background: 'var(--primary)' }}
  //             onClick={() => {
  //               navigator.clipboard.writeText(public_url + 'minimal')
  //               this.setState({ public_viewer_link_clicked: 'minimal' },
  //                 () => modal.update(prevConfig => ({ ...prevConfig })))
  //               // this.handleGetPublicViewer();
  //             }}
  //           >
  //             <p style={{ color: "white" }}>{this.state.public_viewer_link_clicked === 'minimal' ? 'Copied' : 'Click to Copy'}</p>
  //             <Copy size={24} color="white" />
  //           </Button>
  //         </div>
  //         <p>Full interface for professionals</p>
  //         <div className='public-viewer-modal-row'>
  //           <a target='_blank' href={public_url + 'full'}>{public_url}full</a>
  //           <Button
  //             style={{ background: 'var(--primary)' }}
  //             onClick={() => {
  //               navigator.clipboard.writeText(public_url + 'full')
  //               this.setState({ public_viewer_link_clicked: 'full' })
  //               modal.update(prevConfig => ({ ...prevConfig }))
  //               this.handleGetPublicViewer();
  //             }}
  //           >
  //             <p style={{ color: "white" }}>{this.state.public_viewer_link_clicked === 'full' ? 'Copied' : 'Click to Copy'}</p>
  //             <Copy size={24} color="white" />
  //           </Button>
  //         </div>
  //       </div>
  //     ),
  //     onOk() { },
  //   });
  // }

  setXrayModalIdx = (idx) => (e) => {
    if (this.state.files.composite && this.state.files.composite.length)
      idx += 8;
    this.setImgModal(idx)();
  };

  render() {
    const patient = this.state.patient;
    const files = this.state.files;
    const editing_preferences = this.state.editing_preferences;
    const handlePatientFieldChange = this.handlePatientFieldChange;
    const tooth_num_system = this.state.user
      ? this.state.user.attributes["custom:tooth_num_system"]
      : "UNS";
    const fileUploadRef = this.fileUploadRef;
    const avatar =
      files.composite && Object.entries(files.composite).length
        ? files.composite.full_face_smiling ||
          files.composite.full_face_not_smiling ||
          files.composite.full_composite ||
          Object.entries(files.composite)[0][1]
        : "";

    return (
      <Layout
        className="layout patient-view-layout"
        style={{ background: "#fff", wordBreak: "break-all" }}
      >
        <Spin
          spinning={this.state.filesUploading}
          tip={
            this.state.uploadProgress
              ? `Uploading ${this.state.uploadProgress}%`
              : ""
          }
        >
          <Content className="patient-details-layout">
            {/* Header start */}
            <div className="patient-details-header-container">
              <div className="patient-details-header">
                {files.composite && Object.entries(files.composite).length ? (
                  <Avatar
                    className="patient-details-avatar"
                    size={64}
                    src={avatar}
                  />
                ) : // : <div className="patient-details-avatar-placeholder" />}
                null}

                <div className="patient-details-header-info">
                  <p className="patient-details-name">
                    {patient.firstname ? patient.firstname : ""}
                    {patient.lastname ? " " + patient.lastname : ""}
                  </p>
                  {patient.id ? (
                    <span className="patient-id">
                      {"Patient ID: " + patient.id}
                    </span>
                  ) : (
                    <div />
                  )}
                  <div className="patient-details-tag-group">
                    {patient.manufacturing && (
                      <ArchFormTag
                        background="#F4F4F4"
                        color="#46454D"
                        style={{ marginRight: "8px" }}
                      >
                        {patient.manufacturing} Manufacturing
                      </ArchFormTag>
                    )}
                    {patient.alignment && (
                      <ArchFormTag
                        style={{ marginRight: "8px" }}
                        background="#F4F4F4"
                        color="#46454D"
                      >
                        {patient.alignment} Alignment
                      </ArchFormTag>
                    )}
                    {patient.custom_treatment && patient.custom_treatment.length
                      ? patient.custom_treatment.map((tag) => (
                          <Tag
                            style={{ height: 20 }}
                            color={stringToColour(tag)}
                            key={tag}
                          >
                            {tag}
                          </Tag>
                        ))
                      : null}
                  </div>
                </div>

                <div className="patient-details-nav-top-group">
                  <div className="patient-button-group-text">
                    <p
                      className="patient-view-top-bar-status"
                      style={{ color: status_color_map[patient.status] }}
                    >
                      {patient.status}
                    </p>
                    <p style={{ margin: "0px" }}>
                      {status_description[patient.status]}
                    </p>
                  </div>
                  <div className="patient-details-button-group">
                    <StatusSelectorDropdown
                      status={patient.status}
                      setStatus={this.handlePatientStatusChange}
                      setClickStatus={this.handlePatientNextStatusChange}
                    />

                    {patient.status === "Needs Review" && (
                      <>
                        <Button
                          style={{ marginRight: "8px" }}
                          onClick={this.handlePatientNextStatusChange}
                        >
                          <p
                            style={{
                              margin: "0px",
                              color: status_color_map[patient.status],
                            }}
                          >
                            Accept Setup
                          </p>
                        </Button>
                        <Button
                          style={{ marginRight: "8px" }}
                          onClick={this.handlePatientSetupNeedsChanges}
                        >
                          <p
                            style={{
                              margin: "0px",
                              color: status_color_map[patient.status],
                            }}
                          >
                            Setup needs changes
                          </p>
                          <ArrowUpRight size={24} color="white" />
                        </Button>
                      </>
                    )}
                    {!this.state.filesUploading &&
                    files.archform &&
                    files.archform.length &&
                    files.web_viewer &&
                    files.web_viewer.length ? (
                      <Button
                        style={{
                          marginRight: "8px",
                          background: status_color_map[patient.status],
                        }}
                        onClick={() => this.handleOpenWebViewer()}
                      >
                        <p style={{ color: "white" }}>Web Viewer</p>
                        <ArrowUpRight size={24} color="white" />
                      </Button>
                    ) : null}
                    {!this.state.archformFilesLoading ? (
                      <Button
                        style={{
                          marginRight: "8px",
                          background: status_color_map[patient.status],
                        }}
                        onClick={() => this.handleOpenInArchform("")}
                      >
                        <p style={{ color: "white" }}>ArchForm</p>
                        <ArrowUpRight size={24} color="white" />
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* Header end */}

            {/* Main container start  */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "calc(100% - 120px)",
                boxSizing: "border-box",
              }}
            >
              <Tabs
                className="patient-view-container"
                style={{ marginRight: "30px", width: "75%" }}
                defaultActiveKey="1"
                tabBarGutter={1}
                size="small"
              >
                <Tabs.TabPane
                  id="patient-details-comments-tab"
                  tab="Comments"
                  key="1"
                >
                  <div style={{ height: "90%" }} autoFocus>
                    {/* <div style={{margin: '16px 0px 20px 0px', boxSizing: 'border-box' }}> */}
                    <div className="note-notification-reply-content">
                      <Input.TextArea
                        autosize={{ minRows: 3 }}
                        value={this.state.chatMsgText}
                        onChange={(evt) =>
                          this.setState({ chatMsgText: evt.target.value })
                        }
                        placeholder="Type your message . . ."
                      />
                      <div className="note-notification-reply-actions">
                        <label
                          className="paperclip-container"
                          htmlFor={"file-upload-new-comment"}
                        >
                          <Paperclip
                            size={16}
                            color="#A6ABAE"
                            className="comment-file-button"
                          />
                          {this.state.chatMsgFiles.map((file) => (
                            <span key={file.name} className="comment-file-name">
                              {file.name}
                            </span>
                          ))}
                          <input
                            multiple
                            style={{ display: "none" }}
                            id={"file-upload-new-comment"}
                            type="file"
                            onChange={() => {
                              this.handleChatMsgFiles(
                                fileUploadRef.current.files
                              );
                            }}
                            ref={fileUploadRef}
                          />
                        </label>
                        <Button onClick={this.handleNewChatMsg} size="small">
                          <p>Comment</p>
                        </Button>
                      </div>
                      {/* </div> */}
                    </div>

                    {/* {this.state.chatMessages.length === 0 && this.state.chatLoaded ?
                                        <Empty description={<span>No Notes</span>} />
                                        : */}
                    {/* <div style={{ height: '15px' }} /> */}
                    <Spin spinning={!this.state.chatLoaded}>
                      {this.state.chatMessages.length ? (
                        <div
                          style={{
                            position: "absolute",
                            width: "1px",
                            height: "100%",
                            left: "12px",
                            background: "#F4F4F4",
                          }}
                        />
                      ) : null}
                      {!this.state.chatLoaded && (
                        <div style={{ height: "200px" }} />
                      )}
                      {this.state.chatMessages.map((msg) => {
                        if (msg.constructor === Array) {
                          let status = msg;
                          if (status.length === 1) status = status[0];
                          return (
                            <div
                              key={msg.date}
                              style={{
                                display: "flex",
                                justifyContent: "row",
                                alignItems: "center",
                                marginBottom: "24px",
                              }}
                            >
                              {/* <div style={{position: 'absolute', width: '1px', height: '100%', left: '12px', background: '#F4F4F4'}}/> */}
                              <div className="patient-menu-icon-container">
                                <div
                                  className="sidebar-patient-menu-item-icon"
                                  style={{
                                    background: "white",
                                    color: status_color_map[status[0]],
                                    width: "18px",
                                    height: "18px",
                                    margin: "0px",
                                  }}
                                />
                              </div>
                              <span style={{ wordBreak: "break-word" }}>
                                Status changed to{" "}
                                <span className="bold-text">{status[0]}</span>
                              </span>
                              <span style={{ marginLeft: "8px" }}>
                                {moment(status[1]).format("L")}
                              </span>
                            </div>

                            // <Comment

                            //     style={{ background: 'white', border: 'none' }}
                            //     // className='patient-details-comment'
                            //     author={<span>{moment(status[1]).format('L')}</span>}
                            //     avatar={<Avatar icon='info' />}
                            //     content={
                            //         <div >
                            //             <p style={{ wordBreak: 'break-word' }}>Status changed to <Tag color={tag_color[status[0]]}>{status[0]}</Tag></p>
                            //         </div>
                            //     }
                            // datetime={<span>{moment(msg.date).format('MMMM Do YYYY')}</span>}
                            // />
                            // </Col>
                          );
                        } else if (
                          (msg.type && msg.type == "file") ||
                          msg.type == "refinement"
                        ) {
                          // <Col span={24} key={msg.date}>
                          const file_name = msg.message
                            .split("?")[0]
                            .split("/")
                            .slice(-1)[0];
                          return (
                            <>
                              <div
                                key={msg.date}
                                style={{
                                  display: "flex",
                                  justifyContent: "row",
                                  alignItems: "center",
                                  marginBottom: "24px",
                                }}
                              >
                                {/* <div
                                  style={{
                                    position: "absolute",
                                    width: "1px",
                                    height: "100%",
                                    left: "12px",
                                    background: "#F4F4F4",
                                  }}
                                /> */}
                                {/* <div className="patient-menu-icon-container">
                                  <div
                                    className="sidebar-patient-menu-item-icon"
                                    style={{
                                      background: "white",
                                      color: status_color_map[status[0]],
                                      width: "18px",
                                      height: "18px",
                                      margin: "0px",
                                    }}
                                  />
                                </div> */}
                                <div
                                  className="comment-file-icon-container"
                                  style={{
                                    marginRight: "10px",
                                    zIndex: "2",
                                    height: "20px",
                                    paddingLeft: "3px",
                                    backdropFilter: "blur(6px)",
                                  }}
                                >
                                  <Plus size={18} />
                                </div>

                                <span
                                  className="bold-text"
                                  style={{}}
                                  // style={{ wordBreak: "break-word" }}
                                >
                                  New{" "}
                                  {msg.type == "file"
                                    ? " version "
                                    : " refinement "}
                                  {/[0-9]*_[0-9]*_[0-9]*/g.test(file_name)
                                    ? `v${file_name.split("_")[0]}.${
                                        file_name.split("_")[1]
                                      }`
                                    : //   }
                                      //    ${moment(
                                      //     file_name.split("_")[2],
                                      //     "YYYYMMDHms"
                                      //   ).format("lll")
                                      // }
                                      // `
                                      file_name}
                                  {/* New 
                                  <span className="bold-text">{`New file ${msg.message}`}</span> */}
                                </span>
                                <span style={{ marginLeft: "8px" }}>
                                  {moment(msg.date).format("L")}
                                </span>

                                {/* <button className='comment-button'
                                  onClick={() =>
                                    this.handleOpenInArchform(file_name)
                                  }
                                >
                                  Open in ArchForm
                                </button> */}
                              </div>
                              {msg.type && msg.type == "file" ? (
                                <div
                                  className="comment-file-actions-container"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    margin: "20px 40px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      // marginLeft: '40px',
                                      // marginBottom: '20px',
                                      background: "var(--primary)",
                                      justifyContent: "center",
                                    }}
                                    onClick={() =>
                                      this.handleOpenInArchform(file_name)
                                    }
                                    // onClick={() => this.handleOpenInArchform("")}
                                  >
                                    <ArrowUpRight size={24} color="white" />
                                    <p style={{ color: "white" }}>
                                      Open in ArchForm
                                    </p>
                                  </Button>
                                  {this.state.files.web_viewer.includes(
                                    file_name
                                  ) ? (
                                    <Button
                                      style={{
                                        marginLeft: "12px",
                                        // marginBottom: '20px',
                                        background: "var(--primary)",
                                        justifyContent: "center",
                                      }}
                                      onClick={() =>
                                        this.handleOpenWebViewer(file_name)
                                      }
                                      // onClick={() => this.handleOpenInArchform("")}
                                    >
                                      <ArrowUpRight size={12} color="white" />
                                      <p style={{ color: "white" }}>
                                        Webviewer
                                      </p>
                                    </Button>
                                  ) : null}
                                </div>
                              ) : null}
                            </>
                          );
                        } else {
                          return (
                            // <Col span={24} key={msg.date}>
                            <div
                              key={msg.date}
                              style={{ marginBottom: "24px" }}
                            >
                              {/* <div style={{position: 'absolute', width: '1px', height: '100%', left: '12px', background: '#F4F4F4'}}/> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                key={msg.date}
                              >
                                <div className="patient-menu-avatar-container">
                                  <ArchFormAvatar
                                    size={24}
                                    background="#A6ABAE"
                                    text={
                                      msg.username
                                        ? msg.username.split(" ").length > 1
                                          ? msg.username.split(" ")[0][0] +
                                            msg.username.split(" ")[1][0]
                                          : msg.username[0]
                                        : ""
                                    }
                                  />
                                </div>
                                <span style={{ color: "#999999" }}>
                                  <span
                                    className="bold-text"
                                    style={{ marginRight: "8px" }}
                                  >
                                    {msg.username}
                                  </span>
                                  {moment(msg.date).format("L")}
                                </span>
                              </div>

                              <div
                                className="note-notification-content"
                                style={{ border: "none", marginLeft: "25px" }}
                              >
                                <p>{msg.message}</p>
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {this.state.loadedChatMsgFiles &&
                                    this.state.loadedChatMsgFiles[msg.date] &&
                                    this.state.loadedChatMsgFiles[msg.date].map(
                                      (img) => (
                                        <img
                                          alt="note-img"
                                          key={img}
                                          style={{ cursor: "pointer" }}
                                          className="chat-msg-image"
                                          onClick={(e) =>
                                            this.setState({
                                              chatImgModalVisible: true,
                                              chatModalImg: img,
                                            })
                                          }
                                          src={img}
                                        />
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                            // <Comment
                            //     className='patient-details-comment'
                            //     author={<span>{msg.username}</span>}
                            //     avatar={<Avatar icon='user' />}
                            //     content={
                            //         <div >
                            //             <p style={{ wordBreak: 'break-word' }}>{msg.message}</p>
                            //             <div style={{ display: 'flex' }}>
                            //                 {this.state.loadedChatMsgFiles && this.state.loadedChatMsgFiles[msg.date] &&
                            //                     this.state.loadedChatMsgFiles[msg.date].map(img => <img key={img} className='chat-msg-image' onClick={e => this.setState({ chatImgModalVisible: true, chatModalImg: img })} src={img} />)
                            //                 }
                            //             </div>
                            //         </div>
                            //     }
                            //     datetime={<span>{moment(msg.date).format('MMMM Do YYYY')}</span>}
                            // />
                            // </Col>
                          );
                        }
                      })}
                      <div style={{ height: "10px" }} />
                    </Spin>
                    {this.state.chatMessages.length ? (
                      <div
                        style={{
                          background: "white",
                          width: "1px",
                          height: "100%",
                          left: "32px",
                          position: "absolute",
                          transform: "translateY(-24px)",
                        }}
                      />
                    ) : null}
                  </div>
                  {/* } */}
                </Tabs.TabPane>

                <Tabs.TabPane tab="Treatment" key="2">
                  <Row>
                    <Col span={24} style={{ marginTop: "10px" }} />
                    <Col span={12}>
                      <span
                        style={{
                          color: "#757A80",
                          display: "block",
                          lineHeight: "24px",
                          fontSize: "14px",
                        }}
                      >
                        Patient ID
                      </span>
                      {patient.id ? (
                        <span
                          style={{
                            color: "#121925",
                            lineHeight: "32px",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {patient.id}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </Col>
                    <Col span={12}>
                      <span
                        style={{
                          color: "#757A80",
                          display: "block",
                          lineHeight: "24px",
                          fontSize: "14px",
                        }}
                      >
                        Doctor
                      </span>
                      {patient.dentist_email ? (
                        <span
                          style={{
                            color: "#121925",
                            lineHeight: "32px",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {patient.dentist_email}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </Col>

                    <Col span={24} style={{ marginTop: "10px" }} />
                    <Col span={12}>
                      <span
                        style={{
                          color: "#757A80",
                          display: "block",
                          lineHeight: "24px",
                          fontSize: "14px",
                        }}
                      >
                        Gender
                      </span>
                      {patient.gender ? (
                        <span
                          style={{
                            color: "#121925",
                            lineHeight: "32px",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {patient.gender}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </Col>
                    <Col span={12}>
                      <span
                        style={{
                          color: "#757A80",
                          display: "block",
                          lineHeight: "24px",
                          fontSize: "14px",
                        }}
                      >
                        Age
                      </span>
                      {patient.age ? (
                        <span
                          style={{
                            color: "#121925",
                            lineHeight: "32px",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {patient.age}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </Col>

                    {/* <Col span={24} style={{ marginTop: '30px' }} />
                                        <Col span={12} >
                                            <span style={{ color: '#757A80', display: 'block', lineHeight: '24px', fontSize: '14px' }}>Start Date</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400 }}>1/3/19</span>
                                        </Col> */}

                    {/* <Col span={24} style={{ marginTop: '30px' }} />
                                        <Col span={12} >
                                            <span style={{ color: '#757A80', display: 'block', lineHeight: '24px', fontSize: '14px' }}>Address</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400, display: 'block' }}>123 Blueberry Lane</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400 }}>Los Angeles, CA 90210</span>
                                        </Col>
                                        <Col span={12} >
                                            <span style={{ color: '#757A80', display: 'block', lineHeight: '24px', fontSize: '14px' }}>Status</span>
                                            <span style={{ color: '#121925', lineHeight: '32px', fontSize: '16px', fontWeight: 400 }}><Tag color='blue'>{patient.status}</Tag></span>
                                        </Col> */}

                    <Col span={24} style={{ marginTop: "10px" }} />
                    <Col span={12}>
                      <span
                        style={{
                          color: "#757A80",
                          display: "block",
                          lineHeight: "24px",
                          fontSize: "14px",
                        }}
                      >
                        Appointment Date
                      </span>
                      {patient.appointment_date ? (
                        <span
                          style={{
                            color: "#121925",
                            lineHeight: "32px",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {patient.appointment_date}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </Col>
                    <Col span={12}>
                      <span
                        style={{
                          color: "#757A80",
                          display: "block",
                          lineHeight: "24px",
                          fontSize: "14px",
                        }}
                      >
                        Setup Approval Date
                      </span>
                      {patient.setup_approval_date ? (
                        <span
                          style={{
                            color: "#121925",
                            lineHeight: "32px",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {patient.setup_approval_date}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }} />
                    <Col span={12}>
                      <p style={{ color: "#757A80" }}>Clinical Conditons</p>
                    </Col>
                    <Col span={12}>
                      <p style={{ color: "#757A80" }}>Custom Treatment</p>
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }} />

                    <Col span={12}>
                      {
                        patient.clinical_conditions &&
                        patient.clinical_conditions.length ? (
                          <p style={{ color: "#121925" }}>
                            {patient.clinical_conditions
                              .map((condition) =>
                                condition
                                  .split("_")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              )
                              .join(", ")}
                          </p>
                        ) : (
                          <p>-</p>
                        )

                        // <p><Link to={`/edit-patient/${patient.patientid}#3`}><Icon type='edit' style={{ color: '#757A80' }} /></Link></p>
                      }
                    </Col>
                    <Col span={12}>
                      {patient.custom_treatment &&
                      patient.custom_treatment.length ? (
                        patient.custom_treatment.map((tag) => (
                          <Tag color={stringToColour(tag)} key={tag}>
                            {tag}
                          </Tag>
                        ))
                      ) : (
                        <p>-</p>
                      )}
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }} />

                    <Col span={12}>
                      <p style={{ color: "#757A80" }}>Notes</p>
                    </Col>
                    <Col span={12}>
                      <p style={{ color: "#757A80" }}>Shipping Address</p>
                    </Col>
                    <Col span={12}>
                      {patient.notes ? (
                        <p style={{ color: "#121925" }}>{patient.notes}</p>
                      ) : (
                        <p>-</p>
                      )}
                    </Col>
                    <Col span={12}>
                      {patient.shipping_address ? (
                        <AddressViewSimple address={patient.shipping_address} />
                      ) : (
                        <p>-</p>
                      )}
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Prescription" key="3">
                  {patient.clinical_preferences ? (
                    <div className="tab-container">
                      <h4 className="patient-details-subtitle">Prescription</h4>
                      <PrescriptionView
                        patient={this.state.patient}
                        user={this.state.user}
                      />
                    </div>
                  ) : (
                    <Empty
                      style={{ marginTop: "30px" }}
                      description={<span>No prescription found</span>}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Files" key="4">
                  <Row className="patient-file-list">
                    <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                      <h3>X-Rays</h3>
                      <Divider
                        style={{
                          width: "33%",
                          minWidth: "33%",
                          margin: "10px 0 -5px 0",
                        }}
                      />
                      <List
                        itemLayout="vertical"
                        // size="large"
                        dataSource={
                          files["xray"] ? files["xray"] : ["FMX", "Pano"]
                        }
                        split={false}
                        loading={this.state.filesLoading && !files["xray"]}
                        renderItem={(file) => (
                          <List.Item
                            actions={[
                              ...(file.includes("http")
                                ? [
                                    <span>
                                      <Icon
                                        style={{ marginRight: 8 }}
                                        type="download"
                                      />
                                      <a href={file}>Download</a>
                                    </span>,
                                  ]
                                : []),
                              // <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                file.includes("http") ? (
                                  <span>
                                    <Icon
                                      type="file"
                                      style={{ marginRight: 8 }}
                                    />
                                    {file.split("?")[0].split("/").slice(-1)}
                                  </span>
                                ) : (
                                  <span>
                                    <Icon type="file-add" />
                                  </span>
                                )
                              }
                              description={
                                <>
                                  <p className="file-description-label">
                                    {file.toLowerCase().includes("fmx")
                                      ? "FMX"
                                      : "Pano"}
                                  </p>
                                  <p className="file-description-label">
                                    {file in this.state.fileDates
                                      ? moment(
                                          this.state.fileDates[file]
                                        ).format("LLL")
                                      : ""}
                                  </p>
                                </>
                              }
                              style={{ marginBottom: "0px" }}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                    <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                      <h3>3D Models</h3>
                      <Divider
                        style={{
                          width: "33%",
                          minWidth: "33%",
                          margin: "10px 0 -5px 0",
                        }}
                      />
                      <List
                        itemLayout="vertical"
                        // size="large"
                        dataSource={
                          files["models"] ? files["models"] : ["upper", "lower"]
                        }
                        split={false}
                        loading={this.state.filesLoading && !files["models"]}
                        renderItem={(file) => (
                          <List.Item
                            actions={[
                              ...(file.length &&
                              file !== "upper" &&
                              file !== "lower"
                                ? [
                                    <span>
                                      <Icon
                                        style={{ marginRight: 8 }}
                                        type="download"
                                      />
                                      <a href={file}>Download</a>
                                    </span>,
                                  ]
                                : []),
                              ...(file.length &&
                              file !== "upper" &&
                              file !== "lower"
                                ? [
                                    <span>
                                      <Icon
                                        style={{ marginRight: 8 }}
                                        type="edit"
                                      />
                                      <a
                                        onClick={() =>
                                          this.setState({
                                            replaceScansModalVisible: true,
                                          })
                                        }
                                      >
                                        Replace
                                      </a>
                                    </span>,
                                  ]
                                : []),
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                file.includes("http") ? (
                                  <span>
                                    <Icon
                                      type="file"
                                      style={{ marginRight: 8 }}
                                    />
                                    {file.split("?")[0].split("/").slice(-1)}
                                  </span>
                                ) : (
                                  <span>
                                    <Icon type="file-add" />
                                  </span>
                                )
                              }
                              description={
                                <>
                                  <p className="file-description-label">
                                    {file.includes("upper")
                                      ? "STL Upper"
                                      : "STL Lower"}
                                  </p>
                                  <p className="file-description-label">
                                    {file in this.state.fileDates
                                      ? moment(
                                          this.state.fileDates[file]
                                        ).format("LLL")
                                      : ""}
                                  </p>
                                </>
                              }
                              style={{ marginBottom: "0px" }}
                            />
                          </List.Item>
                        )}
                      />
                      <h3>Retainers</h3>
                      <Divider
                        style={{
                          width: "33%",
                          minWidth: "33%",
                          margin: "10px 0 -5px 0",
                        }}
                      />
                      <List
                        itemLayout="vertical"
                        // size="large"
                        dataSource={
                          files["retainers"]
                            ? files["retainers"]
                            : ["upper", "lower"]
                        }
                        split={false}
                        loading={this.state.filesLoading && !files["retainers"]}
                        renderItem={(file) => (
                          <List.Item
                            actions={[
                              ...(file.length &&
                              file !== "upper" &&
                              file !== "lower"
                                ? [
                                    <span>
                                      <Icon
                                        style={{ marginRight: 8 }}
                                        type="download"
                                      />
                                      <a href={file}>Download</a>
                                    </span>,
                                  ]
                                : []),
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                file.includes("http") ? (
                                  <span>
                                    <Icon
                                      type="file"
                                      style={{ marginRight: 8 }}
                                    />
                                    {file.split("?")[0].split("/").slice(-1)}
                                  </span>
                                ) : (
                                  <span>
                                    <Icon type="file-add" />
                                  </span>
                                )
                              }
                              description={
                                <>
                                  <p className="file-description-label">
                                    {file.includes("upper")
                                      ? "STL Upper"
                                      : "STL Lower"}
                                  </p>
                                  <p className="file-description-label">
                                    {file in this.state.fileDates
                                      ? moment(
                                          this.state.fileDates[file]
                                        ).format("LLL")
                                      : ""}
                                  </p>
                                </>
                              }
                              style={{ marginBottom: "0px" }}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                    <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                      <h3>Refinements</h3>
                      {!this.state.filesLoading &&
                        !this.state.filesUploading &&
                        files["refinements"] &&
                        files["refinements"].length >= 2 && (
                          <span>
                            <Icon
                              style={{ marginRight: 8 }}
                              type="cloud-download"
                            />
                            <a
                              onClick={() =>
                                this.handleOpenInArchform(
                                  "",
                                  this.getCurrentRefinementVersion()
                                )
                              }
                            >
                              Open in ArchForm
                            </a>
                          </span>
                        )}

                      <Divider
                        style={{
                          width: "33%",
                          minWidth: "33%",
                          margin: "10px 0 -5px 0",
                        }}
                      />
                      <List
                        itemLayout="vertical"
                        // size="large"
                        dataSource={
                          files["refinements"]
                            ? files["refinements"]
                            : ["upper", "lower"]
                        }
                        split={false}
                        loading={
                          this.state.filesLoading && !files["refinements"]
                        }
                        renderItem={(file) => (
                          <List.Item
                            actions={[
                              ...(file.length &&
                              file !== "upper" &&
                              file !== "lower"
                                ? [
                                    <span>
                                      <Icon
                                        style={{ marginRight: 8 }}
                                        type="download"
                                      />
                                      <a href={file}>Download</a>
                                    </span>,
                                    <span>
                                      <Icon
                                        style={{ marginRight: 8 }}
                                        type="delete"
                                      />
                                      <a
                                        onClick={() =>
                                          this.handleDeleteRefinementFile(
                                            file
                                              .split("?")[0]
                                              .split("/")
                                              .slice(-1)[0],
                                            file
                                          )
                                        }
                                      >
                                        Delete
                                      </a>
                                    </span>,
                                  ]
                                : []),
                              // <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                file.includes("http") ? (
                                  <span>
                                    <Icon
                                      type="file"
                                      style={{ marginRight: 8 }}
                                    />
                                    {file.split("?")[0].split("/").slice(-1)}
                                  </span>
                                ) : (
                                  <span>
                                    <Icon type="file-add" />
                                  </span>
                                )
                              }
                              description={
                                <>
                                  <p className="file-description-label">
                                    {file.includes("upper")
                                      ? "STL Upper"
                                      : "STL Lower"}
                                  </p>
                                  <p className="file-description-label">
                                    {file in this.state.fileDates
                                      ? moment(
                                          this.state.fileDates[file]
                                        ).format("LLL")
                                      : ""}
                                  </p>
                                </>
                              }
                              style={{ marginBottom: "0px" }}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                    <Col xs={24} lg={6} style={{ marginTop: "30px" }}>
                      <h3>Setup Versions</h3>
                      <Upload
                        accept="zip,.archform,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                        name="archform"
                        beforeUpload={() => false}
                        onChange={this.uploadArchformFile}
                        showUploadList={false}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <Icon style={{ marginRight: 8 }} type="plus" />
                          <p style={{ display: "inline" }}>Upload</p>
                        </span>
                      </Upload>

                      <Divider
                        style={{
                          width: "33%",
                          minWidth: "33%",
                          margin: "10px 0 -5px 0",
                        }}
                      />
                      <List
                        itemLayout="vertical"
                        // size="large"
                        dataSource={files["archform"]}
                        split={false}
                        loading={this.state.filesLoading && !files["archform"]}
                        renderItem={(file) => {
                          const file_name = file
                            .split("?")[0]
                            .split("/")
                            .slice(-1)[0];
                          return (
                            <List.Item
                              actions={[
                                <span>
                                  <Icon
                                    style={{ marginRight: 8 }}
                                    type="download"
                                  />
                                  <a href={file}>Download</a>
                                </span>,
                                <span>
                                  <Icon
                                    style={{ marginRight: 8 }}
                                    type="delete"
                                  />
                                  <a
                                    onClick={() =>
                                      this.handleDeleteArchformFile(
                                        file
                                          .split("?")[0]
                                          .split("/")
                                          .slice(-1)[0],
                                        file,
                                        "archform"
                                      )
                                    }
                                  >
                                    Delete
                                  </a>
                                </span>,
                                <span>
                                  <Icon
                                    style={{ marginRight: 8 }}
                                    type="cloud-download"
                                  />
                                  <a
                                    onClick={() =>
                                      this.handleOpenInArchform(
                                        file
                                          .split("?")[0]
                                          .split("/")
                                          .slice(-1)[0]
                                      )
                                    }
                                  >
                                    Open in ArchForm
                                  </a>
                                </span>,
                              ]}
                            >
                              <List.Item.Meta
                                title={[
                                  <span>
                                    <Icon
                                      type="file"
                                      style={{ marginRight: 8 }}
                                    />
                                    {/[0-9]*_[0-9]*_[0-9]*/g.test(file_name)
                                      ? `v${file_name.split("_")[0]}.${
                                          file_name.split("_")[1]
                                        } ${moment(
                                          file_name.split("_")[2],
                                          "YYYYMMDHms"
                                        ).format("lll")}`
                                      : file_name}
                                  </span>,
                                ]}
                                style={{ marginBottom: "0px" }}
                                description={
                                  <p className="file-description-label">
                                    {file in this.state.fileDates
                                      ? moment(
                                          this.state.fileDates[file]
                                        ).format("LLL")
                                      : ""}
                                  </p>
                                }
                              />
                            </List.Item>
                          );
                        }}
                      />
                    </Col>
                    {/* {
                                            ['XRays', 'Models', 'ArchForm'].map(filetype => (
                                                <Col xs={24} lg={8} style={{ marginTop: '30px' }} >
                                                    <h3>{filetype.charAt(0).toUpperCase() + filetype.slice(1)}</h3>
                                                    <Divider style={{ width: '33%', minWidth: '33%', margin: '10px 0 -5px 0' }} />
                                                    <List
                                                        itemLayout="vertical"
                                                        // size="large"
                                                        dataSource={files[filetype.toLowerCase()]}
                                                        split={false}
                                                        loading={this.state.filesLoading && !files[filetype.toLowerCase()]}
                                                        renderItem={file => (
                                                            <List.Item
                                                                actions={[
                                                                    <span><Icon style={{ marginRight: 8 }} type='download' />Download</span>,
                                                                    <span><Icon style={{ marginRight: 8 }} type='edit' />Edit</span>]}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={<Icon type='file' />}
                                                                    title={file.split('?')[0].split('/').slice(-1)}
                                                                    style={{ marginBottom: '0px' }}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </Col>
                                            ))
                                        } */}

                    {/* <Col xs={24} lg={8} style={{ marginTop: '30px' }} >
                                            <List
                                                itemLayout="vertical"
                                                // size="large"
                                                dataSource={files.models}
                                                renderItem={file => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Icon type='file' />}
                                                            title={file.split('?')[0].split('/').slice(-1)}
                                                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} lg={8} style={{ marginTop: '30px' }} >
                                            <List
                                                itemLayout="vertical"
                                                // size="large"
                                                dataSource={files.models}
                                                renderItem={file => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Icon type='file' />}
                                                            title={file.split('?')[0].split('/').slice(-1)}
                                                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Col> */}
                  </Row>
                </Tabs.TabPane>
              </Tabs>
              <div
                className="patient-view-container"
                style={{
                  background: "#fff",
                  justifyContent: "flex-start",
                  width: "25%",
                  padding: "30px",
                  boxSizing: "border-box",
                }}
              >
                {files.composite && Object.values(files.composite).length ? (
                  Object.values(files.composite).length === 1 ? (
                    <div style={{ width: "100%" }}>
                      <p
                        className="sidebar-image-title"
                        style={{ marginBottom: "24px" }}
                      >
                        Patient Composite
                      </p>

                      <img
                        onClick={this.setImgModal(0)}
                        style={{
                          cursor: "pointer",
                          width: "25%",
                          borderRadius: "16px",
                          marginBottom: "20px",
                        }}
                        src={Object.values(files.composite)[0]}
                      />
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <p
                        className="sidebar-image-title"
                        style={{ marginBottom: "24px" }}
                      >
                        Patient Composite
                      </p>

                      {/* <img onClick={this.setImgModal(1)} style={{ cursor: 'pointer', width: '100%', borderRadius: '16px', marginBottom: '20px' }} src={files.composite[1]} /> */}
                      <img
                        onClick={this.setImgModal(0)}
                        className="composite-img"
                        src={
                          files.composite.full_face_smiling ||
                          files.composite.full_composite
                        }
                      />
                      <img
                        onClick={this.setImgModal(1)}
                        className="composite-img"
                        src={files.composite.full_face_not_smiling}
                      />
                      <img
                        onClick={this.setImgModal(2)}
                        className="composite-img-end"
                        src={files.composite.profile_not_smiling}
                      />
                      <div style={{ marginTop: "3px" }} />
                      <img
                        onClick={this.setImgModal(3)}
                        className="composite-img"
                        src={files.composite.upper_occlusal}
                      />
                      <div
                        style={{
                          width: "calc(100%/3)",
                          display: "inline-block",
                          paddingRight: "3px",
                        }}
                      />
                      <img
                        onClick={this.setImgModal(4)}
                        className="composite-img-end"
                        src={files.composite.lower_occlusal}
                      />
                      <div style={{ marginTop: "3px" }} />
                      <img
                        onClick={this.setImgModal(5)}
                        className="composite-img"
                        src={files.composite.right_lateral}
                      />
                      <img
                        onClick={this.setImgModal(6)}
                        className="composite-img"
                        src={files.composite.front_view}
                      />
                      <img
                        onClick={this.setImgModal(7)}
                        className="composite-img-end"
                        src={files.composite.left_lateral}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ width: "100%" }}>
                    {/* <p className='sidebar-image-title' style={{ marginBottom: '24px' }}>Patient Composite</p>

                                        <div className='composite-img-placeholder'> <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div className='composite-img-placeholder' > <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div className='composite-img-placeholder-end' > <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div style={{ marginTop: '3px' }} />
                                        <div className='composite-img-placeholder' > <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div style={{ width: 'calc(100%/3)', display: 'inline-block', paddingRight: '3px' }} > <div></div></div>
                                        <div className='composite-img-placeholder-end' > <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div style={{ marginTop: '3px' }} />
                                        <div className='composite-img-placeholder'  > <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div className='composite-img-placeholder' > <div><Image color='#8A8F98' size='22px' /></div></div>
                                        <div className='composite-img-placeholder-end' > <div><Image color='#8A8F98' size='22px' /></div></div> */}
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  {files.xray && files.xray.length ? (
                    <div style={{ width: "100%" }}>
                      <p
                        className="sidebar-image-title"
                        style={{ margin: "24px 0px" }}
                      >
                        X-Rays
                      </p>
                      {files.xray && files.xray.length && (
                        <img
                          onClick={this.setXrayModalIdx(0)}
                          className="xray-img"
                          src={files.xray[0]}
                        />
                      )}
                      {files.xray && files.xray.length > 1 && (
                        <img
                          onClick={this.setXrayModalIdx(1)}
                          className="xray-img"
                          style={{ paddingRight: "0px" }}
                          src={files.xray[1]}
                        />
                      )}
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      {/* <p className='sidebar-image-title' style={{ margin: '24px 0px' }}>X-Rays</p>
                                            <div className='xray-img-placeholder'> <div><Image color='#8A8F98' size='22px' /></div></div>
                                            <div className='xray-img-placeholder' > <div><Image color='#8A8F98' size='22px' /></div></div> */}
                    </div>
                  )}
                </div>
                {(files.xray && files.xray.length) ||
                (files.composite && files.composite.length) ? (
                  <Divider
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  />
                ) : null}
                <div>
                  <p className="patient-details-sidebar-link">
                    {/* Edit, Plus, File  */}
                    <Edit color="#999999" size={12} />
                    <Link to={`/edit-patient/${this.props.match.params.num}`}>
                      Edit Patient
                    </Link>
                  </p>
                  <p className="patient-details-sidebar-link">
                    <FileReact color="#999999" size={12} />
                    <Link to={`/edit-patient/${this.props.match.params.num}#3`}>
                      Edit Prescription
                    </Link>
                  </p>
                  <p
                    onClick={() =>
                      this.setState({ newRefinementModalVisible: true })
                    }
                    className="patient-details-sidebar-link"
                  >
                    <Plus color="#999999" size={12} />
                    Create Refinement
                  </p>
                  <p className="patient-details-sidebar-link">
                    <Plus color="#999999" size={12} />
                    <Link to={`/edit-patient/${this.props.match.params.num}#2`}>
                      Upload Files
                    </Link>
                  </p>
                  <Popconfirm
                    title="Permanently delete this patient?"
                    onConfirm={this.handleDeletePatient}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <p className="patient-details-sidebar-link">
                      <Trash color="#999999" size={12} />
                      Delete Patient
                    </p>
                  </Popconfirm>
                  <p
                    onClick={() =>
                      this.setState({
                        publicWebViewerLinkModalVisible: true,
                        publicWebViewerLinkClicked: false,
                      })
                    }
                    className="patient-details-sidebar-link"
                  >
                    <Plus color="#999999" size={12} />
                    Shareable 3D Viewer
                  </p>
                  <p
                    onClick={() =>
                      this.setState({ retainerOrderModalVisible: true })
                    }
                    className="patient-details-sidebar-link"
                  >
                    <Plus color="#999999" size={12} />
                    Order Retainers
                  </p>
                </div>
                {/* <p className='patient-details-sidebar-link' >Order Aligners</p> */}
              </div>
            </div>
          </Content>
        </Spin>
        {this.state.imgModalVisible && (
          <Icon
            type="caret-left"
            onClick={(ev) => {
              if (this.carousel && this.carousel.current)
                this.carousel.current.prev();
            }}
            style={{
              fontSize: "8em",
              color: "white",
              position: "absolute",
              top: "50%",
              zIndex: 1001,
              left: "5%",
            }}
          />
        )}
        {this.state.imgModalVisible && (
          <Icon
            type="caret-right"
            onClick={(ev) => {
              if (this.carousel && this.carousel.current)
                this.carousel.current.next();
            }}
            style={{
              fontSize: "8em",
              color: "white",
              position: "absolute",
              top: "50%",
              zIndex: 1001,
              right: "5%",
            }}
          />
        )}

        <Modal
          // title="Basic Modal"
          visible={this.state.imgModalVisible}
          footer={null}
          closable={false}
          // onOk={this.handleOk}
          onCancel={() => this.setState({ imgModalVisible: false })}
          width="80%"
          className="patient-image-modal"
        >
          <Carousel
            ref={this.carousel}
            initialSlide={this.state.carouselIdx}
            dotPosition="top"
          >
            {files.composite &&
              Object.values(files.composite).length &&
              (Object.values(files.composite).length === 1 ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{
                      maxHeight: "90vh",
                      width: "95%",
                      objectFit: "contain",
                      display: "inherit",
                    }}
                    onClick={() => this.setState({ imgModalVisible: false })}
                    src={Object.values(files.composite)[0]}
                  />
                  <h3
                    style={{
                      textAlign: "center",
                      lineHeight: "60px",
                      color: "#fff",
                    }}
                  >
                    Full Composite
                  </h3>
                </div>
              ) : (
                [
                  ["full_face_smiling", "Full face smiling"],
                  ["full_face_not_smiling", "Full face not smiling"],
                  ["profile_not_smiling", "Profile"],
                  ["upper_occlusal", "Upper Occlusal"],
                  ["lower_occlusal", "Lower Occlusal"],
                  ["right_lateral", "Right Lateral"],
                  ["front_view", "Front"],
                  ["left_lateral", "Left Lateral"],
                ].map(([idx, label]) =>
                  files.composite[idx] || files.composite.full_composite ? (
                    <div key={label} tyle={{ textAlign: "center" }}>
                      <img
                        style={{
                          maxHeight: "90vh",
                          width: "95%",
                          objectFit: "contain",
                          display: "inherit",
                        }}
                        src={
                          files.composite[idx] || files.composite.full_composite
                        }
                        onClick={() =>
                          this.setState({ imgModalVisible: false })
                        }
                      />
                      <h3
                        style={{
                          textAlign: "center",
                          lineHeight: "60px",
                          color: "#fff",
                        }}
                      >
                        {label}
                      </h3>
                    </div>
                  ) : null
                )
              ))}
            {files.xray
              ? [
                  [0, "FMX X-Ray"],
                  [1, "Pano X-Ray"],
                ].map(([idx, label]) =>
                  files.xray[idx] ? (
                    <div key={label} style={{ textAlign: "center" }}>
                      <img
                        style={{
                          maxHeight: "90vh",
                          width: "95%",
                          objectFit: "contain",
                          display: "inherit",
                        }}
                        src={files.xray[idx]}
                        onClick={() =>
                          this.setState({ imgModalVisible: false })
                        }
                      />
                      <h3
                        style={{
                          textAlign: "center",
                          lineHeight: "60px",
                          color: "#fff",
                        }}
                      >
                        {label}
                      </h3>
                    </div>
                  ) : null
                )
              : null}
          </Carousel>
        </Modal>
        <Modal
          title={this.state.chatMsgTitle}
          visible={this.state.chatModalVisible}
          forceRender={true}
          closable={true}
          onOk={this.handleNewChatMsg}
          onCancel={() => {
            this.setState({
              chatModalVisible: false,
              chatMsgText: "",
              chatMsgTitle: "Edit Note",
              chatMsgFiles: [],
            });
            this.fileUploadRef.current.value = "";
          }}
        >
          <Upload
            name="archform"
            beforeUpload={() => false}
            onChange={(e) => {
              const { fileList } = e;
              const files = fileList.map((file) => file.originFileObj);
              this.handleChatMsgFiles(files);
            }}
            showUploadList={true}
            fileList={this.state.chatMsgFiles.map((file, i) => {
              return {
                uid: `file.name${i}`,
                name: file.name,
                originFileObj: file,
                type: file.type,
              };
            })}
            // fileList={this.state.chatMsgFiles}
            previewVisible={true}
            listType="picture-card"
          >
            <span style={{ cursor: "pointer" }}>
              <Icon style={{ marginRight: 8 }} type="plus" />
              <p style={{ display: "inline" }}>Upload</p>
            </span>
          </Upload>
          <TextArea
            style={{ marginTop: "1em" }}
            autosize={{ minRows: 8 }}
            value={this.state.chatMsgText}
            onChange={(evt) => this.setState({ chatMsgText: evt.target.value })}
          />
        </Modal>
        <Modal
          visible={this.state.chatImgModalVisible}
          footer={null}
          closable={true}
          onCancel={() => this.setState({ chatImgModalVisible: false })}
          className="patient-image-modal"
          bodyStyle={{ width: "100%" }}
          width="100%"
        >
          <div
            style={{ textAlign: "center", width: "100%" }}
            onClick={() => this.setState({ chatImgModalVisible: false })}
          >
            <img
              alt="note-img"
              style={{ height: "90vh", maxWidth: "90%", objectFit: "contain" }}
              src={this.state.chatModalImg}
            />
          </div>
        </Modal>
        <Modal
          id="create-refinement-modal"
          title="Create Refinement"
          visible={this.state.newRefinementModalVisible}
          closable={true}
          onOk={this.handleNewRefinement}
          onCancel={() => this.setState({ newRefinementModalVisible: false })}
          width={690}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                padding: "0",
                cursor: "pointer",
              }}
            >
              <FileUpload
                setFile={(file) =>
                  this.setState({
                    newUpperRefinementFile: file,
                  })
                }
                label="STL Upper"
                preview={stl_upper_outline}
                uploadedPreview={stl_upper_fill}
                accept={SCANS_ACCRPTED}
              />
              <FileUpload
                setFile={(file) =>
                  this.setState({
                    newLowerRefinementFile: file,
                  })
                }
                label=" STLLower"
                preview={stl_lower_outline}
                uploadedPreview={stl_lower_fill}
                accept={SCANS_ACCRPTED}
              />
              {/* <Upload
                name="stl_upper"
                accept=".stl"
                beforeUpload={() => false}
                onChange={(file) =>
                  this.setState({
                    newUpperRefinementFile: file.fileList.slice(-1)[0],
                  })
                }
              >
                <img style={{ width: "100%" }} src={stl_upper_bw} />
                <p>Upper</p>
              </Upload>
              <Upload
                name="stl_lower"
                accept=".stl"
                beforeUpload={() => false}
                onChange={(file) =>
                  this.setState({
                    newLowerRefinementFile: file.fileList.slice(-1)[0],
                  })
                }
              >
                <img style={{ width: "100%" }} src={stl_lower_bw} />
                <p>Lower</p>
              </Upload> */}
            </div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "18px",
                letterSpacing: "-.04em",
                marginTop: "32px",
              }}
            >
              Add clinical comments or concerns
            </p>

            <div className="note-notification-reply-content">
              <Input.TextArea
                autosize={{ minRows: 3 }}
                value={this.state.chatMsgText}
                onChange={(evt) =>
                  this.setState({ chatMsgText: evt.target.value })
                }
                placeholder="Type your message . . ."
              />
              <div className="note-notification-reply-actions"></div>
            </div>

            <h3>Patient photos</h3>
            <div className="patient-photos-container">
              {[
                ["Full face smiling", full_face_smiling],
                ["Full face not smiling", full_face_not_smiling],
                ["Profile Not Smiling", profile_not_smiling],
                ["Upper Occlusal", upper_occlusal],
                ["Full Composite"],
                ["Lower Occlusal", lower_occlusal],
                ["Right lateral", right_lateral],
                ["Front View", front_view],
                ["Left lateral", left_lateral],
              ].map((photo) => {
                const photo_var = photo[0].toLowerCase().replace(/ /g, "_");
                return (
                  <FileUpload
                    key={photo[0]}
                    file={this.state.refinementComposite[photo_var]}
                    setFile={((photo_file) =>
                      this.setState({
                        refinementComposite: {
                          ...this.state.refinementComposite,
                          [photo_var]: photo_file,
                        },
                      })).bind(this)}
                    label={photo[0]}
                    preview={photo[1]}
                    accept=".jpeg,.jpg,.png"
                  />
                );
              })}
            </div>
            {/* <p
              style={{
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '18px',
                letterSpacing: '-.04em',
                marginTop: '32px'
              }}
            >Add updated patient photos</p>
            <Button
              style={{
                justifyContent: 'center'
              }}
              onClick={() =>
                this.handleOpenInArchform(file_name)
              }
            onClick={() => this.handleOpenInArchform("")}
            >
              <p >Add photos</p>
            </Button> */}
          </div>
        </Modal>
        <Modal
          id="replace-scans-modal"
          title="Update 3D Models"
          visible={this.state.replaceScansModalVisible}
          closable={true}
          onOk={this.handleUpdateScans}
          onCancel={() => this.setState({ replaceScansModalVisible: false })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0",
              cursor: "pointer",
            }}
          >
            <Upload
              name="stl_upper"
              accept={SCANS_ACCRPTED}
              beforeUpload={() => false}
              onChange={(file) =>
                this.setState({
                  replacementUpperScanFile: file.fileList.slice(-1)[0],
                })
              }
            >
              <img style={{ width: "100%" }} src={stl_upper_bw} />
              <p>Upper</p>
            </Upload>
            <Upload
              name="stl_lower"
              accept={SCANS_ACCRPTED}
              beforeUpload={() => false}
              onChange={(file) =>
                this.setState({
                  replacementLowerScanFile: file.fileList.slice(-1)[0],
                })
              }
            >
              <img style={{ width: "100%" }} src={stl_lower_bw} />
              <p>Lower</p>
            </Upload>
          </div>
        </Modal>
        <Modal
          visible={this.state.publicWebViewerLinkModalVisible}
          closable={true}
          onOk={() => this.setState({ publicWebViewerLinkModalVisible: false })}
          onCancel={() =>
            this.setState({ publicWebViewerLinkModalVisible: false })
          }
          cancelButtonProps={{ disabled: true }}
          footer={null}
          title="Direct link to the 3D web viewer"
          width={800}
          className="public-viewer-modal"
        >
          {files.web_viewer && files.web_viewer.length ? (
            <div className="public-viewer-modal-container">
              <p>Minimal view for patients</p>
              <div className="public-viewer-modal-row">
                <a
                  target="_blank"
                  href={this.getPublicWebViewerUrl() + "minimal"}
                >
                  {this.getPublicWebViewerUrl()}minimal
                </a>
                <Button
                  style={{
                    background: "var(--primary)",
                    justifyContent:
                      this.state.publicWebViewerLinkClicked === "minimal"
                        ? "center"
                        : "unset",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      this.getPublicWebViewerUrl() + "minimal"
                    );
                    this.setState({ publicWebViewerLinkClicked: "minimal" });
                  }}
                >
                  <p style={{ color: "white" }}>
                    {this.state.publicWebViewerLinkClicked === "minimal"
                      ? "Copied"
                      : "Click to Copy"}
                  </p>
                  <Copy size={24} color="white" />
                </Button>
              </div>
              <p>Full interface for professionals</p>
              <div className="public-viewer-modal-row">
                <a target="_blank" href={this.getPublicWebViewerUrl() + "full"}>
                  {this.getPublicWebViewerUrl()}full
                </a>
                <Button
                  style={{
                    background: "var(--primary)",
                    justifyContent:
                      this.state.publicWebViewerLinkClicked === "full"
                        ? "center"
                        : "unset",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      this.getPublicWebViewerUrl() + "full"
                    );
                    this.setState({ publicWebViewerLinkClicked: "full" });
                  }}
                >
                  <p style={{ color: "white" }}>
                    {this.state.publicWebViewerLinkClicked === "full"
                      ? "Copied"
                      : "Click to Copy"}
                  </p>
                  <Copy size={24} color="white" />
                </Button>
              </div>
            </div>
          ) : (
            <p>Please upload an Archform file to create a shareable link</p>
          )}
        </Modal>
        <RetainerOrder
          retainerOrderModalVisible={this.state.retainerOrderModalVisible}
          setRetainerOrderModalVisible={((retainerOrderModalVisible) =>
            this.setState({ retainerOrderModalVisible })).bind(this)}
          handleNewRetainerOrder={this.handleNewRetainerOrder}
        />
      </Layout>
    );
  }
}

export default withRouter(PatientView);
