import { Cache, Auth, Storage } from "aws-amplify";
import { getAPIData, updateAPIData, deleteAPIData } from "./APIData";
import { getDentist } from "./Dentists";
import { gzip, ungzip } from "node-gzip";
import { get_value, set_value } from "./db";

const S3_DATA_BUCKET = "archform-patient-data-v1";
const S3_SETUP_BUCKET = "archform-patients-v1";

const path = "getpatients3"; //"patientdata"

export async function getPatients() {
  try {
    const creds = await Auth.currentCredentials();
    const usertype = Cache.getItem("user")["custom:user_type"];
    const params = {
      queryStringParameters: {
        userid: creds.identityId,
        usertype: usertype,
        callLimit: true,
      },
    };
    let data = await getAPIData(path, params);
    data = Uint8Array.from(data.data);
    data = JSON.parse(await ungzip(data));
    let patients = data.items;
    let lastEval = data.lastEval;

    while (lastEval) {
      params.queryStringParameters.lastEval = JSON.stringify(lastEval);
      data = await getAPIData(path, params);
      data = Uint8Array.from(data.data);
      data = JSON.parse(await ungzip(data));
      lastEval = data.lastEval;
      patients = [...patients, ...data.items];
    }
    let techLastEval = data.techLastEval;
    while (techLastEval) {
      params.queryStringParameters.techLastEval = JSON.stringify(techLastEval);
      data = await getAPIData(path, params);
      data = Uint8Array.from(data.data);
      data = JSON.parse(await ungzip(data));
      techLastEval = data.techLastEval;
      patients = [...patients, ...data.items];
    }
    let manufacturingLastEval = data.manufacturingLastEval;
    while (manufacturingLastEval) {
      params.queryStringParameters.manufacturingLastEval = JSON.stringify(
        manufacturingLastEval
      );
      data = await getAPIData(path, params);
      data = Uint8Array.from(data.data);
      data = JSON.parse(await ungzip(data));
      manufacturingLastEval = data.manufacturingLastEval;
      patients = [...patients, ...data.items];
    }
    data = patients;
    if (!Array.isArray(data)) data = [];
    data = data.filter((patient) => patient.firstname);
    data = data.filter((patient) =>
      usertype === "Technician"
        ? patient.alignment && patient.alignment !== "In Office"
        : true
    );
    await set_value("pd", data);

    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function getPatientAvatar(patient) {
  const creds = await Auth.currentCredentials();
  const options = {
    level: "protected",
    identityId: patient.userid,
    bucket: "archform-patient-data-v1-resized",
  };
  const avatar = await Storage.get(
    `${patient.patientid}/composite/full_face_smiling.jpg`,
    options
  );
  return avatar;
}

export async function getPatientAvatars() {
  const patients = await get_value("pd");
  if (!patients) return;
  const avatars = {};
  for (const patient of patients) {
    try {
      const avatar = await getPatientAvatar(patient);
      if (avatar) {
        avatars[patient.patientid] = avatar;
      } else {
        avatars[patient.patientid] = false;
      }
    } catch (err) {
      avatars[patient.patientid] = false;
    }
  }
  await set_value("pa", avatars);
  return avatars;
}

export async function deletePatient(patientid) {
  try {
    const creds = await Auth.currentCredentials();
    const params = {
      queryStringParameters: { userid: creds.identityId, patientid: patientid },
    };
    let data = await deleteAPIData("patientdata", params);
    const patients = await get_value("pd");
    const idx = patients.findIndex(
      (patient) => patient.patientid === patientid
    );
    if (idx > -1) patients.splice(idx, 1);
    await set_value("pd", patients);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export function getPatientChat(patientid) {
  return new Promise((resolve, reject) => {
    Auth.currentCredentials()
      .then((creds) => {
        const params = { queryStringParameters: { patientid: patientid } };
        getAPIData("patientchat", params)
          .then((data) => {
            resolve(data);
          })
          .catch((data) => reject(data));
      })
      .catch((err) => reject(err));
  });
}

export function getAligners(patientid) {
  return new Promise((resolve, reject) => {
    Auth.currentCredentials()
      .then((creds) => {
        const params = { queryStringParameters: { patientid: patientid } };
        getAPIData("aligners", params)
          .then((data) => {
            // Cache.setItem('patientdata', data);
            resolve(data);
          })
          .catch((data) => reject(data));
      })
      .catch((err) => reject(err));
  });
}

export async function updateAligners(aligner_data) {
  try {
    const aligner = {
      patientid: aligner_data.patientid,
      stage: aligner_data.stage,
    };
    for (const prop in ["start_date, end_date"]) {
      if (aligner_data[prop]) aligner[prop] = aligner_data[prop];
    }

    const data = await updateAPIData("aligners", { body: aligner });
    return data;
  } catch (err) {
    console.log(err);
  }
}

export function updatePatient(patient) {
  return new Promise((resolve, reject) => {
    Auth.currentCredentials()
      .then((creds) => {
        patient.lastModified = new Date().toISOString();
        if (
          (!patient.technician || patient.technician === "None") &&
          patient.alignment &&
          patient.alignment !== "In Office"
        ) {
          const primary_technician =
            Cache.getItem("user")["custom:primary_technician"];
          if (primary_technician) {
            patient.technician = primary_technician;
          } else {
            patient.technician =
              "us-east-2:20107894-2123-4efa-9d62-a0265d4b160f";
          }
        }
        if (!patient.status_timeline) {
          patient.status_timeline = [[patient.status, patient.lastModified]];
        } else if (
          patient.status_timeline[patient.status_timeline.length - 1][0] !==
          patient.status
        ) {
          patient.status_timeline.push([
            [patient.status, patient.lastModified],
          ]);
        }
        updateAPIData("patientdata", { body: patient })
          .then(async (data) => {
            const patients = await get_value("pd");
            // delete patient.userid;
            if (patients) {
              const idx = patients.findIndex(
                (fpatient) => fpatient.patientid === patient.patientid
              );
              patients[idx] = patient;
              await set_value("pd", patients);
            }
            resolve(data);
          })
          .catch((data) => reject(data));
      })
      .catch((err) => reject(err));
  });
}

export function createPatient(patient) {
  return new Promise((resolve, reject) => {
    Auth.currentCredentials()
      .then((creds) => {
        patient.userid = creds.identityId;
        patient.dentist_email = Cache.getItem("user").email;
        patient.lastModified = new Date().toISOString();
        if (!patient.created) patient.created = patient.lastModified;
        if (!patient.status) patient.status = "Unprocessed";
        if (
          (!patient.technician || patient.technician === "None") &&
          patient.alignment &&
          patient.alignment !== "In Office"
        ) {
          const primary_technician =
            Cache.getItem("user")["custom:primary_technician"];
          if (primary_technician) {
            patient.technician = primary_technician;
          } else {
            patient.technician =
              "us-east-2:20107894-2123-4efa-9d62-a0265d4b160f";
          }
        }
        patient.status_timeline = [[patient.status, patient.lastModified]];
        updateAPIData("patients", { body: patient })
          .then(async (data) => {
            await getPatients();
            // const patients = Cache.getItem('patientdata');
            // patients.push(patient);
            // Cache.setItem('patientdata', patients);
            resolve(data);
          })
          .catch((data) => reject(data));
      })
      .catch((err) => reject(err));
  });
}

export async function updatePatientChat(message) {
  try {
    // message.userid = (await Auth.currentCredentials()).data.IdentityId;
    // message.date = (new Date()).toISOString();
    // message.username = Cache.getItem('user').name;
    const msg = {
      userid: message.userid,
      date: message.date,
      username: message.username,
      patientid: message.patientid,
      message: message.message,
      patient_userid: message.patient_userid,
    };
    const data = await updateAPIData("patientchat", { body: msg });
    return msg;
  } catch (err) {
    console.log(err);
  }
}

export async function getPatientNotifications() {
  try {
    const creds = await Auth.currentCredentials();
    const params = { queryStringParameters: { userid: creds.identityId } };
    let data = await getAPIData("notifications", params);
    if (!Array.isArray(data)) data = [];
    // data = data.filter((patient) => patient.firstname);
    // data = data.filter((patient) => usertype === 'Technician' ? patient.alignment && patient.alignment !== 'In Office' : true);
    // Cache.setItem('notifications', data, { priority: 5 });
    // console.log(data)
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function updatePatientNotification(notification) {
  try {
    const creds = await Auth.currentCredentials();
    const params = {
      queryStringParameters: {
        userid: creds.identityId,
        notificationid: notification.notificationid,
      },
    };
    let data = await updateAPIData("notifications", params);
    // console.log(data)
    return data;
  } catch (err) {
    console.error(err);
  }
}

export function hashCode(patient) {
  let str = patient.userid + patient.patientid;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString();
}

// breaks IE
export async function sha(patient) {
  const msgUint8 = new TextEncoder().encode(patient.userid + patient.patientid);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

// types
// composite
// xray
// models
// refinements
// setup
export async function uploadPatientFile(
  filename,
  filedata,
  type,
  patient,
  uploadProgress
) {
  const userid = patient.userid;
  const patientid = patient.patientid;
  const key = `${patient.patientid}/${filename}`;
  const options = {
    level: "protected",
    identityId: userid,
    bucket: type === "setup" ? S3_SETUP_BUCKET : S3_DATA_BUCKET,
  };
  if (type === "setup") {
    options.customPrefix = { protected: "" };
  }

  try {
    const res = await Storage.put(key, filedata, {
      ...options,
      // resumable: true,
      useAccelerateEndpoint: true,
      // region: "us-east-\2",
      progressCallback: (progress) =>
        uploadProgress(parseInt((100 * progress.loaded) / progress.total)),
      completeCallback: (event) => {
        console.log(event);
      },
      errorCallback: (err) => {
        console.log(err);
        throw new Error(err);
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
}
